// src/index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { ChakraProvider, CSSReset, extendTheme } from '@chakra-ui/react';

const root = createRoot(document.getElementById('root'));

// Define your custom theme
const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: '#111106',
        color: 'white',
        backgroundImage: './bg-simple.svg',
        backgroundPosition: 'top',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        h: '100vh',
        overflow: '0',
        fontFamily: '"Poppins", sans-serif'
      },
    },
  },
});

root.render(<React.StrictMode>
  <ChakraProvider theme={theme}>
    <CSSReset />
    <App />
  </ChakraProvider>
  </React.StrictMode>);