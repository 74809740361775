// src/App.js
import React from 'react';
import Navigation from './components/Navigation';
import Features from './components/Features';
import Notice from './components/Notice';
import Stats from './components/Stats'
import Cta from './components/Cta';
import CtaFlipped from './components/CtaFlipped';
import HeroText from './components/HeroText';
import FeeGen from './components/FeeGen';
import Bsv from './components/Bsv';


const App = () => {
  return (
    <>
      <Notice />
      <Navigation />
      <HeroText />
      <Features />
    </>
  );
};

export default App;
