// src/components/Notice.js
import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { BsExclamationSquareFill } from "react-icons/bs";

const Notice = () => {
    return (
        <Box
            p="4"
            bg="rgba(255, 255, 255, 0.1)"
            color="#fff"
            textAlign="left"
            backdropFilter="blur(10px)"
            display='flex'
            alignItems='center'
        >
            <Text ml='2' fontSize="1rem" >
                Pool and Bridge will be ready as soon as the network upgrades conclude. All trading is suspended during this period.
            </Text>
        </Box>
    );
};

export default Notice;
