// src/components/Features.js
import React from 'react';
import { Box, Heading, SimpleGrid, Text, VStack, Image, Button, useToast } from '@chakra-ui/react';
import { GoArrowRight } from "react-icons/go";

const Features = () => {
  const toast = useToast();

  const handleComingSoonButtonClick = () => {
    toast({
        title: "Coming Soon...",
        description: "Get ready! Mainnet Dapps are launching soon...",
        status: "info",
        duration: 5000, // Duration of the toast notification
        isClosable: true, // Allow the user to close the toast manually
        position: 'top',
    });
};

const handleRedactedButtonClick = () => {
    toast({
        title: "Redacted",
        description: "Shhh it's a secret...",
        status: "info",
        duration: 5000, // Duration of the toast notification
        isClosable: true, // Allow the user to close the toast manually
        position: 'top',
    });
};
  return (
    <Box mt='10' p={{base: 4, md: 20, lg: 20}} >
      <SimpleGrid columns={{ base: 1, md: 4, lg: 4 }} spacing={8}>
        {/* Feature 1 */}
        <VStack align="start" backdropFilter="blur(10px)" bg="#29290E" p='4' borderRadius='10' border='1px solid #696923'>
          <Box p='2' borderRadius='10' backdropFilter="blur(10px)" bg="rgba(255, 255, 255, 0.1)">
            <Text fontSize='1.4rem'><Image height='40px' src='./blastverse.svg' /></Text>
          </Box>
          <Heading mt='20' fontSize="1.4rem">Blastverse</Heading>
          <Text fontSize="0.8rem">
            The hub for all Blastverse Dapps. Launchpad and governance.
          </Text>
          <Button as="a" href="https://portal.blastverse.world" mt='4' rightIcon={<GoArrowRight />} backgroundColor='#111106' border='1px solid #696923' color='#FCFC03' _hover={{backgroundColor: '#1A1A09'}} fontSize='0.8rem' borderRadius='full' >Go to Portal</Button>
        </VStack>

        {/* Feature 2 */}
        <VStack align="start" backdropFilter="blur(10px)" bg="#29290E" p='4' borderRadius='10' border='1px solid #696923'>
          <Box p='2' borderRadius='10' backdropFilter="blur(10px)" bg="rgba(255, 255, 255, 0.1)">
            <Text fontSize='1.4rem' ><Image height='40px' src='./beamex.svg' /></Text>
          </Box>
          <Heading mt='20' fontSize="1.4rem">Beamex</Heading>
          <Text fontSize="0.8rem">
            Swap, Supply and Farm. Native DEX on blast with concentrated liquidity.
          </Text>
          <Button onClick={handleComingSoonButtonClick} mt='4' rightIcon={<GoArrowRight />} backgroundColor='#111106' border='1px solid #696923' color='#FCFC03' _hover={{backgroundColor: '#1A1A09'}} fontSize='0.8rem' borderRadius='full' >Go to Beamex</Button>
        </VStack>

        <VStack align="start" backdropFilter="blur(10px)" bg="#29290E" p='4' borderRadius='10' border='1px solid #696923'>
          <Box p='2' borderRadius='10' backdropFilter="blur(10px)" bg="rgba(255, 255, 255, 0.1)">
            <Text fontSize='1.4rem' ><Image height='40px' src='./redacted-blur.png' /></Text>
          </Box>
          <Heading mt='20' fontSize="1.4rem" textDecoration='line-through' >Redacted</Heading>
          <Text fontSize="0.8rem">
            Liquid staking<br></br> █████ █████ █████.
          </Text>
          <Button onClick={handleRedactedButtonClick} mt='4' rightIcon={<GoArrowRight />} backgroundColor='#111106' border='1px solid #696923' color='#FCFC03' _hover={{backgroundColor: '#1A1A09'}} fontSize='0.8rem' borderRadius='full' >SoonTM</Button>
        </VStack>

        {/* Feature 4 */}
        <VStack align="start" backdropFilter="blur(10px)" bg="#29290E" p='4' borderRadius='10' border='1px solid #696923'>
          <Box p='2' borderRadius='10' backdropFilter="blur(10px)" bg="rgba(255, 255, 255, 0.1)">
            <Text fontSize='1.4rem' ><Image height='40px' src='./redacted-blur.png' /></Text>
          </Box>
          <Heading mt='20' fontSize="1.4rem" textDecoration='line-through' >Redacted</Heading>
          <Text fontSize="0.8rem">
            Lend & Borrow<br></br> █████ █████ █████.
          </Text>
          <Button onClick={handleRedactedButtonClick} mt='4' rightIcon={<GoArrowRight />} backgroundColor='#111106' border='1px solid #696923' color='#FCFC03' _hover={{backgroundColor: '#1A1A09'}} fontSize='0.8rem' borderRadius='full' >SoonTM</Button>
        </VStack>
      </SimpleGrid>
    </Box>
  );
};

export default Features;
