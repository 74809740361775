// src/components/Navigation.js
import React, { useState } from 'react';
import { Flex, Link, Button, Image, Text, Drawer, DrawerOverlay, DrawerContent, DrawerBody, DrawerFooter, HStack, useToast } from '@chakra-ui/react';
import { FaDiscord, FaBars } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { GoArrowRight } from "react-icons/go";

const Navigation = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const toast = useToast();

    const handleDrawerOpen = () => {
        setIsDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setIsDrawerOpen(false);
    };

    const handleComingSoonButtonClick = () => {
        toast({
            title: "Coming Soon...",
            description: "Get ready! Mainnet Dapps are launching soon...",
            status: "info",
            duration: 5000, // Duration of the toast notification
            isClosable: true, // Allow the user to close the toast manually
            position: 'top',
        });
    };

    const handleDocsButtonClick = () => {
        toast({
            title: "Loading Docs...",
            description: "Our docs are going through final review, check back later...",
            status: "info",
            duration: 5000, // Duration of the toast notification
            isClosable: true, // Allow the user to close the toast manually
            position: 'top',
        });
    };

    const handleRedactedButtonClick = () => {
        toast({
            title: "Redacted",
            description: "Shhh it's a secret...",
            status: "info",
            duration: 5000, // Duration of the toast notification
            isClosable: true, // Allow the user to close the toast manually
            position: 'top',
        });
    };

    return (
        <Flex justify="space-between" align="center" p={4} bg="transparent" color="white" borderBottom='1px solid #1F1F14'>
            <Flex alignItems='center'>
                <Image mr='4' h='38px' src='./logo.svg' />
                <Link mx={2} href="#" onClick={handleDrawerOpen}>
                    <Text fontSize='0.8rem' ><FaBars /></Text>
                </Link>
                <Link onClick={handleDocsButtonClick} >
                <Text ml='4' fontSize='0.8rem' >Docs</Text>
                </Link>

                {/* Drawer Component */}
                <Drawer isOpen={isDrawerOpen} onClose={handleDrawerClose} placement="left">
                    <DrawerOverlay />
                    <DrawerContent backdropFilter="blur(10px)" bg="rgba(255, 255, 255, 0.1)">
                        <DrawerBody fontSize='1rem' >
                            {/* List of links with icons */}
                            <HStack mt='4' p="2" spacing={4} align="center">
                            <Image h='28px' src='./launchpad-icon.svg'/>
                                <Link href="https://portal.blastverse.world" _hover={{ textDecoration: "none", color: '#ACACAC' }}>
                                    Blastverse
                                </Link>
                            </HStack>
                            <HStack p="2" spacing={4} align="center">
                            <Image h='28px' src='./swap-icon.svg'/>
                                <Link onClick={handleComingSoonButtonClick} _hover={{ textDecoration: "none", color: '#ACACAC' }}>
                                    Beamex
                                </Link>
                            </HStack>
                            <HStack p="2" spacing={4} align="center">
                            <Image h='28px' src='./lend-icon.svg'/>
                                <Link textDecoration='line-through' onClick={handleRedactedButtonClick} _hover={{ textDecoration: "line-through", color: '#ACACAC' }}>
                                    Redacted
                                </Link>
                            </HStack>
                            <HStack p="2" spacing={4} align="center">
                            <Image h='28px' src='./liquid-icon.svg'/>
                                <Link textDecoration='line-through' onClick={handleRedactedButtonClick} _hover={{ textDecoration: "line-through", color: '#ACACAC' }}>
                                    Redacted
                                </Link>
                            </HStack>
                            {/* Add more links as needed */}
                        </DrawerBody>
                        <DrawerFooter>
                            <Text textAlign='left' fontSize='0.8rem' color='#fff' >Blastverse<br></br>v1.0.0 Beta</Text>
                            <Image ml='16' w='140px' src='./builtonblast.svg' />
                        </DrawerFooter>
                    </DrawerContent>
                </Drawer>
            </Flex>

            <Flex alignItems='center'>
                <Flex mr='4' alignItems='center' >
                    <Link mx={2} href="#">
                        <FaSquareXTwitter />
                    </Link>
                    <Link mx={2} href="#">
                        <FaDiscord />
                    </Link>
                </Flex>
                <Button as="a" href="https://portal.blastverse.world" rightIcon={<GoArrowRight />} backgroundColor='#29290E' border='1px solid #696923' color='#FCFC03' _hover={{backgroundColor: '#1A1A09'}} fontSize='0.8rem' borderRadius='full' >Portal</Button>
            </Flex>
        </Flex>
    );
};

export default Navigation;
