import React, { useState, useEffect } from 'react';
import {
    Box,
    Text,
    Button,
    Center,
    Flex,
} from '@chakra-ui/react';
import { GoArrowRight } from "react-icons/go";

const HeroText = () => {

    return (
        <>
            <Box textAlign="center" mt='40' >
                <Center mb='4' >
                    <Flex>
                        <Text fontWeight='bold' fontSize={{base: '2rem', md: '3rem', lg: '4rem'}} color="white" >Your ETH, Your Yield</Text>
                    </Flex>
                </Center>
                <Center>
                    <Flex>
                        <Text fontWeight='bold' fontSize='1rem' color="rgba(255, 255, 255, 0.8)" >100% revenue and yield back to you. Native on Blast.</Text>
                    </Flex>
                </Center>
                {/* Display remaining time */}
                <Text mt='8' fontSize='0.8rem' >BSV Public pool:</Text>
                <Text fontSize='1.6rem' color='#FCFC03' >Awaiting Network Upgrade</Text>
                <Text fontSize='1rem' color='white' >Pool and Bridge will be deployed as soon as the network upgrades conclude.<br></br>All trading is suspended during this period.</Text>
            </Box>
        </>
    );
};

export default HeroText;
